/***
 * @description 登录
 */
import axios from '@/lib/axios.js';
import localstorage from '@/lib/localstorage';
import { localStorageKey } from '../localStorageKey';
import {
    editForm,
    loginUrl,
    loginoutUrl,
    userUrl,
    groupList,
    saveGroup,
    saveGroupPower,
    accountList,
    saveAccount,
    createAccout,
    userList,
    serveList,
    saveServe,
    serveView,
    delServe,
    reservationList,
    reservationView,
    delReservation,
    checkTempList,
    checkTempView,
    saveCheckTemp,
    delCheckTemp,
    saveCheckClass,
    delCheckClass,
    checkList,
    checkView,
    checkClassList,
    bannerList,
    saveBanner,
    delBanner,
    bannerView,
    sendReport,
    switchStatus,
    subForm,
    agentlistUrl,
    addAgentUrl,
    updateAgentUrl,
    readAgentUrl,
    modPasswodeUrl,
    deleteUrl,
    cancelUrl,
    saveFastappsUrl,
    getFastappsUrl,
    orderUrl,
    agentUrl,
    userstatUrl,
    paperListUrl,
    saveRuleUrl,
    processUrl,
    paperSaveUrl,
    paperReadUrl,
    paperEndUrl,
    paperPublishUrl,
    photometryUrl,
    massListUrl,
    massSaveUrl,
    agentStaticUrl,
    getWdLogs,
    saveFinanceRuleUrl,
    getFinanceRuleUrl,
    wdCheckUrl,
    getRuleUrl,
    totalFundsUrl,
    incExpLogUrl,
    wdImportUrl,
    subtplListUrl,
    tplCropSaveUrl,
    departmentListUrl,
    tplCropReadUrl,
    simpleListUrl,
    tplCropDeleteUrl,
    abnormalUrl,
    shareUrl,
    tplMpListUrl,
    mpTplListUrl,
    tplMpSaveUrl,
    tplMpReadUrl,
    tplMpDeleteUrl,
    pnTplListUrl,
    tplPnListUrl,
    tplPnSaveUrl,
    tplPnReadUrl,
    tplPnDeleteUrl,
    wxAuthUrl
} from '@/lib/api';

function devUrl(_url) {
    let url = _url;
    console.log('process.env.NODE_ENV=', process.env.NODE_ENV);
    if (process.env.NODE_ENV === 'development') {
        url = '/api' + _url;
    }
    console.log('devUrl url = ', url, url.length);
    return url;
}

function parseParam(params = {}) {
    let _params = structuredClone(params);
    let token = localstorage.get(localStorageKey.ACCESS_TOKEN_QILANG);
    if (!!token) {
        _params.token = token;
    }
    return _params;
}
//公众号绑定
export function wxAuth(param = {}) {
    return axios.postBody(devUrl(wxAuthUrl), parseParam(param));
}
//公众号删除
export function tplPnDelete(param = {}) {
    return axios.postBody(devUrl(tplPnDeleteUrl), parseParam(param));
}
//公众号详情
export function tplPnRead(param = {}) {
    return axios.postBody(devUrl(tplPnReadUrl), parseParam(param));
}
//公众号保存
export function tplPnSave(param = {}) {
    return axios.postBody(devUrl(tplPnSaveUrl ), parseParam(param));
}

//公众号列表
export function tplPnList(param = {}) {
    return axios.postBody(devUrl(tplPnListUrl), parseParam(param));
}
//公众号选择列表
export function pnTplList(param = {}) {
    return axios.postBody(devUrl(pnTplListUrl), parseParam(param));
}
//订阅删除
export function tplMpDelete(param = {}) {
    return axios.postBody(devUrl(tplMpDeleteUrl), parseParam(param));
}
//订阅详情
export function tplMpRead(param = {}) {
    return axios.postBody(devUrl(tplMpReadUrl), parseParam(param));
}
//订阅保存
export function tplMpSave(param = {}) {
    return axios.postBody(devUrl(tplMpSaveUrl), parseParam(param));
}

//模板选择
export function mpTplList(param = {}) {
    return axios.postBody(devUrl(mpTplListUrl), parseParam(param));
}
//小程序消息订阅
export function tplMpList(param = {}) {
    return axios.postBody(devUrl(tplMpListUrl), parseParam(param));
}
//不合格因素
export function abnormal(param = {}) {
    return axios.postBody(devUrl(abnormalUrl), parseParam(param));
}
//分享
export function getShare(param = {}) {
    return axios.postBody(devUrl(shareUrl), parseParam(param));
}
//企微列表删除
export function tplCropDelete(param = {}) {
    return axios.postBody(devUrl(tplCropDeleteUrl), parseParam(param));
}
//成员列表
export function simpleList(param = {}) {
    return axios.postBody(devUrl(simpleListUrl), parseParam(param));
}
//模板详情
export function tplCropRead(param = {}) {
    return axios.postBody(devUrl(tplCropReadUrl), parseParam(param));
}
//部门列表
export function getDepartmentList(param = {}) {
    return axios.postBody(devUrl(departmentListUrl), parseParam(param));
}
//保存
export function tplCropSave(param = {}) {
    return axios.postBody(devUrl(tplCropSaveUrl), parseParam(param));
}
//企微模板列表
export function getSubtplList(param = {}) {
    return axios.postBody(devUrl(subtplListUrl), parseParam(param));
}
//导入
export function wdImport(param = {}) {
    return axios.postBody(devUrl(wdImportUrl), parseParam(param));
}
//佣金明细
export function getincExpLog(param = {}) {
    return axios.postBody(devUrl(incExpLogUrl), parseParam(param));
}
//剩余佣金
export function totalFunds(param = {}) {
    return axios.postBody(devUrl(totalFundsUrl), parseParam(param));
}
//分销商统计
export function agentStatic(param = {}) {
    return axios.postBody(devUrl(agentStaticUrl), parseParam(param));
}
//消息保存
export function massSave(param = {}) {
    return axios.postBody(devUrl(massSaveUrl), parseParam(param));
}
//定时群发消息
export function massList(param = {}) {
    return axios.postBody(devUrl(massListUrl), parseParam(param));
}
//测光统计
export function photometry(param = {}) {
    return axios.postBody(devUrl(photometryUrl), parseParam(param));
}
//手动开启关闭
export function paperPublish(param = {}) {
    return axios.postBody(devUrl(paperPublishUrl), parseParam(param));
}
//手动结束
export function paperEnd(param = {}) {
    return axios.postBody(devUrl(paperEndUrl), parseParam(param));
}
//问卷详情
export function paperRead(param = {}) {
    return axios.postBody(devUrl(paperReadUrl), parseParam(param));
}
//问卷调查保存
export function paperSave(param = {}) {
    return axios.postBody(devUrl(paperSaveUrl), parseParam(param));
}
//审核
export function processSub(param = {}) {
    return axios.postBody(devUrl(processUrl), parseParam(param));
}
//分销规则
export function saveRule(param = {}) {
    return axios.postBody(devUrl(saveRuleUrl), parseParam(param));
}
//问卷调查列表
export function getPaperList(param = {}) {
    return axios.postBody(devUrl(paperListUrl), parseParam(param));
}
//用户统计
export function getUser(param = {}) {
    return axios.postBody(devUrl(userstatUrl), parseParam(param));
}
//经销商统计
export function getAgentStatic(param = {}) {
    return axios.postBody(devUrl(agentUrl), parseParam(param));
}
//订单统计
export function getOrder(param = {}) {
    return axios.postBody(devUrl(orderUrl), parseParam(param));
}
//获取快捷应用
export function getFastapps(param = {}) {
    return axios.postBody(devUrl(getFastappsUrl), parseParam(param));
}
//保存快捷应用
export function saveFastapps(param = {}) {
    return axios.postBody(devUrl(saveFastappsUrl), parseParam(param));
}
//暂停
export function cancel(param = {}) {
    return axios.postBody(devUrl(cancelUrl), parseParam(param));
}
//删除
export function deleteItem(param = {}) {
    return axios.postBody(devUrl(deleteUrl), parseParam(param));
}
//修改密码
export function modPasswode(param = {}) {
    return axios.postBody(devUrl(modPasswodeUrl), parseParam(param));
}
//详情
export function readAgent(param = {}) {
    return axios.postBody(devUrl(readAgentUrl), parseParam(param));
}
//编辑省代理
export function updateAgent(param = {}) {
    return axios.postBody(devUrl(updateAgentUrl), parseParam(param));
}
//新增省代理

export function addAgent(param = {}) {
    return axios.postBody(devUrl(addAgentUrl), parseParam(param));
}
//分销列表
export function getAgentList(param = {}) {
    return axios.postBody(devUrl(agentlistUrl), parseParam(param));
}

//登录
export function login(param = {}) {
    return axios.postBody(devUrl(loginUrl), parseParam(param));
}

//退出登录
export function loginout(param = {}) {
    return axios.postBody(devUrl(loginoutUrl), parseParam(param));
}

//用户权限
export function user(param = {}) {
    return axios.postBody(devUrl(userUrl), parseParam(param));
}

//角色列表
export function reqGroupList(param = {}) {
    return axios.postBody(devUrl(groupList), parseParam(param));
}

//创建、修改角色
export function reqSaveGroup(param = {}) {
    return axios.postBody(devUrl(saveGroup), parseParam(param));
}

//修改角色权限
export function reqSaveGroupPower(param = {}) {
    return axios.postBody(devUrl(saveGroupPower), parseParam(param));
}

//账号列表
export function reqAccountList(param = {}) {
    return axios.postBody(devUrl(accountList), parseParam(param));
}

//修改账号
export function reqSaveAccount(param = {}) {
    return axios.post(devUrl(saveAccount), parseParam(param));
}

//创建账号
export function reqCreateAccount(param = {}) {
    return axios.postBody(devUrl(createAccout), parseParam(param));
}

//用户列表
export function reqUserList(param = {}) {
    return axios.postBody(devUrl(userList), parseParam(param));
}

//服务列表
export function reqServeList(param = {}) {
    return axios.postBody(devUrl(serveList), parseParam(param));
}

//修改创建服务
export function reqSaveServe(param = {}) {
    return axios.post(devUrl(saveServe), parseParam(param));
}

//服务详情
export function reqServeView(param = {}) {
    return axios.postBody(devUrl(serveView), parseParam(param));
}

//删除服务
export function reqDelServe(param = {}) {
    return axios.postBody(devUrl(delServe), parseParam(param));
}

//预约订单列表
export function reqReservationList(param = {}) {
    return axios.postBody(devUrl(reservationList), parseParam(param));
}

//预约单详情
export function reqReservationView(param = {}) {
    return axios.postBody(devUrl(reservationView), parseParam(param));
}

//删除预约单
export function reqDelReservation(param = {}) {
    return axios.postBody(devUrl(delReservation), parseParam(param));
}

//检测管理 start  ------------------------------------------------------------------------------------

//检测模版列表
export function reqCheckTempList(param = {}) {
    return axios.postBody(devUrl(checkTempList), parseParam(param));
}

//检测模版详情
export function reqCheckTempView(param = {}) {
    return axios.postBody(devUrl(checkTempView), parseParam(param));
}

//创建更新模版
export function reqSaveCheckTemp(param = {}) {
    return axios.postBody(devUrl(saveCheckTemp), parseParam(param));
}

//删除检测模版
export function reqDelCheckTemp(param = {}) {
    return axios.postBody(devUrl(delCheckTemp), parseParam(param));
}

//检测分类列表
export function reqCheckClassList(param = {}) {
    return axios.postBody(devUrl(checkClassList), parseParam(param));
}

//创建更新分类
export function reqSaveCheckClass(param = {}) {
    return axios.postBody(devUrl(saveCheckClass), parseParam(param));
}

//删除检测分类
export function reqDelCheckClass(param = {}) {
    return axios.postBody(devUrl(delCheckClass), parseParam(param));
}

//检测单列表
export function reqCheckList(param = {}) {
    return axios.postBody(devUrl(checkList), parseParam(param));
}

//检测单详情
export function reqCheckView(param = {}) {
    return axios.postBody(devUrl(checkView), parseParam(param));
}
//检测单编辑
export function geteditForm(param = {}) {
    return axios.postBody(devUrl(editForm), parseParam(param));
}
//检测单保存
export function saveReservation(param = {}) {
    return axios.postBody(devUrl(subForm), parseParam(param));
}
//发送报告
export function reqSendReport(param = {}) {
    return axios.postBody(devUrl(sendReport), parseParam(param));
}

//检测管理 end------------------------------------------------------------------------------------

//Banner列表
export function reqBannerList(param = {}) {
    return axios.postBody(devUrl(bannerList), parseParam(param));
}

//创建更新Banner
export function reqSaveBanner(param = {}) {
    return axios.postBody(devUrl(saveBanner), parseParam(param));
}

//删除Banner
export function reqDelBanner(param = {}) {
    return axios.postBody(devUrl(delBanner), parseParam(param));
}

//Banner详情
export function reqBannerView(param = {}) {
    return axios.postBody(devUrl(bannerView), parseParam(param));
}

// 修改账号状态
export function reqSwitchStatus(param = {}) {
    return axios.postBody(devUrl(switchStatus), parseParam(param));
}

// 获取提现申请列表
export function getWdLogsList(param = {}) {
    return axios.postBody(devUrl(getWdLogs), parseParam(param));
}
// 奖品规则保存
export function saveFinanceRule(param = {}) {
    return axios.postBody(devUrl(saveFinanceRuleUrl), parseParam(param));
}
//获取财务规则
export function getFinanceRule(param = {}) {
    return axios.postBody(devUrl(getFinanceRuleUrl), parseParam(param));
}
//提现审核
export function wdCheck(param = {}) {
    return axios.postBody(devUrl(wdCheckUrl), parseParam(param));
}
//获取分销规则
export function getRule(param = {}) {
    return axios.postBody(devUrl(getRuleUrl), parseParam(param));
}


