<template>
    <searchBody>
        <search :show-create="true" class="searchbar" :label-width="80" :searchs="searchs" @search="onSearch" @reset="reset" @create="onCreate()"></search>
        <Card class="table_container" ref="table_container">
            <Table stripe class="table mt_10" :columns="dataColumn" :data="dataList" row-key="id">
                <template slot-scope="{ row }" slot="status">
                    <span :class="'now_state_' + row.status">{{ { 0: '关闭', 1: '启用' }[row.status] }}</span>
                </template>                
                <template slot-scope="{ row }" slot="manager">
                    <div class="table_manager flex flex_wrap align_center">
                        <Button v-if="'children' in row" type="success" size="small" ghost @click="onCreate(row, true)">添加子类</Button>
                        <Button type="primary" size="small" ghost @click="onCreate(row)">编辑</Button>
                        <Button type="error" size="small" ghost @click="onDelete(row)">删除</Button>
                    </div>
                </template>
            </Table>
        </Card>
        <div class="relative width_100_percent text_align_right">
            <Page size="small" :total="totalPageNum || 1" :page-size="1" show-elevator @on-change="currentChange" />
        </div>
        <Modal :closable="false" v-model="showCreate" :title="title" width="500" :loading="ajaxLoading">
            <div v-if="!!formData.pid && !!!formData.id" class="form_item">
                <div class="form_label must_input">父类</div>
                {{ formData.ptitle }}
            </div>
            <div class="form_item">
                <div class="form_label must_input">分类名称</div>
                <Input v-model.trim="formData.title" :maxlength="15" show-word-limit></Input>
            </div>
            <div class="form_item">
                <div class="form_label must_input">状态</div>
                <RadioGroup v-model="formData.status" type="button" button-style="solid">
                    <Radio label="1">开启</Radio>
                    <Radio label="0">关闭</Radio>
                </RadioGroup>
            </div>
            <div slot="footer" class="relative width_100_percent flex align_center justify_center">
                <Button type="primary" ghost @click="onCancelCreate">取消</Button>
                <Button class="ml_100" type="primary" :loading="ajaxLoading" @click="onConfirmCreate">确定</Button>
            </div>
        </Modal>

        <mySpin :loading="ajaxLoading"></mySpin>
    </searchBody>
</template>
<script>
import pageMixins from '@/lib/pageMixins';
import tableSearch from '@/components/tableSearch';
import searchBody from '@/components/searchBody';
import { reqCheckClassList, reqDelCheckClass, reqSaveCheckClass } from '@/lib/request/auth';

export default {
    name: 'monitorManagerTypeList',
    mixins: [pageMixins],
    components: {
        search: tableSearch,
        searchBody,
    },
    data() {
        return {
            searchs: [
                { id: hm.createId(), label: '编号', placeholder: '输入编号', type: 'input', bind: 'id' },
                { id: hm.createId(), label: '关键词', placeholder: '输入关键词', type: 'input', bind: 'title' },
                {
                    id: hm.createId(),
                    label: '状态',
                    placeholder: '选择状态',
                    type: 'select',
                    bind: 'status',
                    list: [
                        { id: '1', title: '开启' },
                        { id: '0', title: '关闭' },
                    ],
                },
            ],
            dataColumn: [
                { key: 'id', title: '编号', minWidth: 80, tree: true },
                { key: 'title', title: '分类名称', minWidth: 150, tree: true },
                { slot: 'status', title: '状态', minWidth: 100 },
                { slot: 'manager', title: '操作', minWidth: 120 },
            ],
            dataList: [],
            showCreate: false,
            title: null,
            formData: {
                pid: 0,
                ptitle: null,
                id: null,
                title: null,
                status: '1',                
            },
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        //获取列表数据
        getList() {
            this.showAjaxLoading();
            reqCheckClassList(this.params)
                .then(res => {
                    this.dataInjectDataList(res);
                    this.dataList.forEach(info => {
                        info._showChildren = true;
                    });
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        //新增/编辑
        onCreate(row, isAddChild = false) {
            let { id, title, status } = row || {};
            console.log('onCreate', id);

            this.formData.pid = 0;
            this.formData.ptitle = null;
            this.formData.id = null;
            this.formData.title = null;
            this.formData.status = '1';

            this.title = '添加分类';
            if (!!id && isAddChild) {
                this.title = '添加子类';
                this.formData.pid = id;
                this.formData.ptitle = title;
            } else if (!!id) {
                this.title = '编辑分类';
                this.formData.pid = row.pid || 0;
                this.formData.id = id;
                this.formData.title = title;
                this.formData.status = status;
            }
            this.showCreate = true;
        },
        //删除
        onDelete(row) {
            let { id, title } = row || {};
            this.confirmDialog(`确认删除【${title}】吗`).then(isOK => {
                if (isOK == 1) {
                    this.showAjaxLoading();
                    reqDelCheckClass({ id })
                        .then(res => {
                            this.fadeAlert(res.errmsg);
                            this.getList();
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                }
            });
        },
        //取消 添加/修改分类
        onCancelCreate() {
            this.showCreate = false;
        },
        //确认添加/修改分类
        onConfirmCreate() {
            this.showAjaxLoading();
            let { pid, id, title, status } = this.formData || {};
            if (!hm.isNotEmptyString(title)) {
                this.hideAjaxLoading();
                this.fadeWarning('请输入分类名称');
                return;
            }

            reqSaveCheckClass({ pid, id, title, status })
                .then(res => {
                    this.fadeAlert(res.errmsg);
                    this.onCancelCreate();
                    this.getList();
                })
                .catch(() => {
                    this.hideAjaxLoading();
                });
        },
    },
};
</script>
<style lang="less" scoped>
.form_label {
    width: 100px;
}
</style>
